var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { attrs: { label: _vm.label } },
    [
      _c(
        "b-input-group",
        [
          _vm.isForNumber
            ? _c("reporting-custom-number-input", {
                class: {
                  "bg-color-grams-hair": _vm.isDisable,
                  "br-none": _vm.enableExpend || _vm.addUnit,
                  "custom-radius": !_vm.enableExpend && !_vm.addUnit,
                },
                attrs: {
                  "is-disable": _vm.isDisable,
                  "is-for-currency": _vm.isForCurrency,
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _c("b-form-input", {
                class: {
                  "bg-color-grams-hair": _vm.isDisable,
                  "br-none": _vm.enableExpend || _vm.addUnit,
                  "custom-radius": !_vm.enableExpend && !_vm.addUnit,
                },
                attrs: { value: _vm.value, disabled: _vm.isDisable },
                on: {
                  change: function ($event) {
                    return _vm.$emit("change", $event)
                  },
                },
              }),
          _c(
            "b-input-group-append",
            [
              _vm.enableExpend
                ? _c(
                    "b-input-group-text",
                    {
                      staticClass: "input-group-text-bg-grams-hair px-1",
                      class: {
                        "bg-color-grams-hair": _vm.isDisable,
                        "bg-color-white": !_vm.isDisable,
                        "rounded-0": _vm.addUnit,
                      },
                    },
                    [
                      _vm._t("action-icon", function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-transparent text-color-rhapsody-in-blue",
                            },
                            [
                              _c(_vm.getLucideIcon("ExternalLink"), {
                                tag: "component",
                                attrs: {
                                  size: 20,
                                  "stroke-width": 1.5,
                                  color: "#787878",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.addUnit
                ? _c(
                    "b-input-group-text",
                    {
                      staticClass:
                        "input-group-text bg-color-north-wind py-0 px-3 text-white",
                    },
                    [_vm._t("append-icon")],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }