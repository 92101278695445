<template>
	<b-form-group :label="label">
		<b-input-group>
			<reporting-custom-number-input
				v-if="isForNumber"
				v-model="value"
				:is-disable="isDisable"
				:is-for-currency="isForCurrency"
				:class="{ 'bg-color-grams-hair': isDisable, 'br-none': enableExpend || addUnit, 'custom-radius': !enableExpend && !addUnit }"
			></reporting-custom-number-input>
			<b-form-input
				v-else
				:value="value"
				:class="{ 'bg-color-grams-hair': isDisable, 'br-none': enableExpend || addUnit, 'custom-radius': !enableExpend && !addUnit }"
				:disabled="isDisable"
				@change="$emit('change', $event)"
			/>
			<b-input-group-append>
				<b-input-group-text
					class="input-group-text-bg-grams-hair px-1"
					:class="{
						'bg-color-grams-hair': isDisable,
						'bg-color-white': !isDisable,
						'rounded-0': addUnit
					}"
					v-if="enableExpend"
				>
					<slot name="action-icon">
						<button class="btn-transparent text-color-rhapsody-in-blue">
							<component :is="getLucideIcon('ExternalLink')" :size="20" :stroke-width="1.5" color="#787878" />
						</button>
					</slot>
				</b-input-group-text>
				<b-input-group-text class="input-group-text bg-color-north-wind py-0 px-3 text-white" v-if="addUnit">
					<slot name="append-icon"></slot>
				</b-input-group-text>
			</b-input-group-append>
		</b-input-group>
	</b-form-group>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import ReportingCustomNumberInput from '@/modules/carbonclap/components/ReportingCustomNumberInput';

export default {
	name: 'CustomFormGroup',

	mixins: [globalMixin],

	components: {
		ReportingCustomNumberInput
	},

	props: {
		label: { type: String, default: '', required: false },
		value: { type: [String, Number], required: true },
		isDisable: { type: Boolean, default: false, required: true },
		enableExpend: { type: Boolean, default: false, required: false },
		addUnit: { type: Boolean, default: false, required: true },
		isForNumber: { type: Boolean, default: true, required: false },
		isForCurrency: { type: Boolean, default: false, required: false }
	}
};
</script>

<style>
.custom-radius {
	border-radius: 8px !important;
}
</style>
