<template>
	<VueNumberFormat v-model="inputValue" :options="fieldOptions" :disabled="isDisable" class="form-control"></VueNumberFormat>
</template>

<script>
import { store } from '@/store';
import VueNumberFormat from '@igortrindade/vue-number-format';

export default {
	name: 'CarbonclapComponentsReportinfCustomNumberInput',

	components: {
		VueNumberFormat
	},

	props: {
		value: { type: [String, Number], required: true },
		isDisable: { type: Boolean, default: false, required: true },
		isForCurrency: { type: Boolean, default: false, required: false }
	},

	computed: {
		inputValue: {
			get() {
				return this.value;
			},
			set(status) {
				this.$emit('input', status);
			}
		},
		isEnglish() {
			return store.appLanguage() === 0;
		},
		fieldOptions() {
			return {
				precision: this.isInteger(this.value) ? 0 : 2,
				prefix: '',
				isInteger: this.isInteger(this.value),
				decimal: this.isEnglish ? '.' : ',',
				thousand: this.isEnglish ? ',' : '.'
			};
		}
	},

	methods: {
		isInteger(num) {
			return num % 1 === 0 && !this.isForCurrency;
		}
	}
};
</script>
