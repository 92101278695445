import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { LOCATION_FIELD } from './locations.crud';

export const getProjectLocationListForPremises = async () => {
	try {
		const query = gql`
			query {
				GetProjectLocationList(RemovePremise: false, RemoveLocationPME: true, RemoveLocationDecorFilm: true) {
					${LOCATION_FIELD}
				}
			}
		`;
		const {
			data: { GetProjectLocationList }
		} = await apollo.query({
			query,
			fetchPolicy: 'no-cache'
		});
		//console.log("in load location list:",GetProjectLocationList);
		return GetProjectLocationList;
	} catch (e) {
		console.log({ e });
	}
};

export const createPremise = async (Kind = 0) => {
	try {
		const mutation = gql`
			mutation ($Kind: ID) {
				NewLocation(Kind: $Kind) {
          ${LOCATION_FIELD}
				}
			}
		`;
		const {
			data: { NewLocation }
		} = await apollo.mutate({
			mutation,
			variables: {
				Kind: Kind
			}
		});

		return NewLocation;
	} catch (e) {
		console.log({ e });
	}
};
export const updatePremise = async (id, premise) => {
	if (_.isNil(id)) return;
	try {
		const mutation = gql`
			mutation ($LocationId: ID!, $UpdatedLocation: LocationInput!) {
				UpdLocation(LocationId: $LocationId, UpdatedLocation: $UpdatedLocation) {
					${LOCATION_FIELD}
				}
			}
		`;

		const {
			data: { UpdLocation }
		} = await apollo.mutate({
			mutation,
			variables: {
				LocationId: parseInt(id, 10),
				UpdatedLocation: premise
			}
		});

		return UpdLocation;
	} catch (e) {
		console.log({ e });
	}
};

export const deleteLocation = async (id) => {
	try {
		const mutation = gql`
			mutation ($LocationId: ID!) {
				DelLocation(LocationId: $LocationId)
			}
		`;
		const {
			data: { DelLocation }
		} = await apollo.mutate({
			mutation,
			variables: {
				LocationId: parseInt(id)
			}
		});
		return DelLocation;
	} catch (e) {
		console.log({ e });
	}
};

export const createAndUpdateComputerEquipment = async (ComputerEquipmentId, UpdComputerEquipment) => {
	try {
		const mutation = gql`
			mutation ($ComputerEquipmentId: ID!, $UpdComputerEquipment: ComputerEquipmentInput!) {
				AddUpdComputerEquipment(ComputerEquipmentId: $ComputerEquipmentId, UpdComputerEquipment: $UpdComputerEquipment) {
					id
					locationId
					type
					description
					quantity
					depreciation
				}
			}
		`;
		const {
			data: { AddUpdComputerEquipment }
		} = await apollo.mutate({
			mutation,
			variables: {
				ComputerEquipmentId: ComputerEquipmentId,
				UpdComputerEquipment: UpdComputerEquipment
			}
		});
		return AddUpdComputerEquipment;
	} catch (error) {
		console.log(error);
	}
};

export const delComputerEquipment = async (ComputerEquipmentId) => {
	try {
		const mutation = gql`
			mutation ($ComputerEquipmentId: ID!) {
				DelComputerEquipment(ComputerEquipmentId: $ComputerEquipmentId)
			}
		`;
		const {
			data: { DelComputerEquipment }
		} = await apollo.mutate({
			mutation,
			variables: {
				ComputerEquipmentId
			}
		});
		return DelComputerEquipment;
	} catch (error) {
		console.log(error);
	}
};
